html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 13px;
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: 'Poppins', sans-serif;
}

div {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

img,
div,
button,
input {
  box-sizing: border-box;
}

#userinfo-container div,
#password-container {
  width: unset;
}

div.MuiAutocomplete-root,
.MuiAutocomplete-root div {
  width: unset;
  flex-direction: row;
}

.custom-select div {
  width: unset;
  flex: unset;
  flex-direction: row;
}

.custom-select div:nth-child(1) {
  flex-direction: column;
}

.loader {
  flex: unset;
}

#root {
  width: 100%;
  height: 100%;
}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-canary {
  display: none;
}

.mapboxgl-canvas-container {
  display: block;
}

.mapboxgl-control-container {
  display: none;
}

.info-tooltip {
  white-space: pre-wrap;
  /*overflow: hidden;*/
  max-width: 220px;
  border-radius: 8px;
}

.info-tooltip a {
  color: white;
}
div.ReactModal__Content {
  width: unset;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  font-size: 14px;
  border-radius: 16px;
  color: #354353;
  border: 1px solid #f3f3f4;
  background-color: #f3f3f4;
}

.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']:hover,
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid #f3f3f4;
}

div.MuiPaper-rounded {
  margin: 6px 0;
  border-radius: 20px;
  font-family: Poppins, sans-serif;
}

.toast-container div {
  flex-direction: initial;
}
