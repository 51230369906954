.card-radio label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 28px;
  gap: 12px;

  /* Neutrals/500 */
  border: 1px solid #f3f3f4;

  /* Shadows/Extra Large */
  box-shadow: 0px 25px 50px -12px rgba(106, 111, 123, 0.25);
  border-radius: 16px;
}

.card-radio label .description {
  text-align: center;
}

.card-radio label .description .title {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
}

.card-radio label .description .subtitle {
  color: #3d4353;
}

.card-radio input[type='radio'] {
  display: none;
}

.card-radio input[type='radio']:checked + label {
  border: 2px solid #ff9126;
}

.card-radio input[type='radio']:checked:disabled + label {
  border: 2px solid transparent;
}
